
import { defineComponent, onMounted, reactive, ref } from "vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import * as Mast from "@/models/MasterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import BnPHelpers from "@/assets/ts/_utils/BnPHelpers";
//import { CsvExportModule } from "@ag-grid-community/csv-export";

export default defineComponent({
  name: "alarms-page",
  components: {
    AgGridVue,
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { t } = useI18n();
    //const rowData = ref<Mast.Alarm[]>([] as Mast.Alarm[]);//reactive([]);
    const alarmHistory = ref<Array<Mast.AlarmHistory>>([]);

    let today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 30);
    const searchFilter = ref<Mast.AlarmEventSearchRequest>({
      searchString: null,
      parentIds: null,
      pageSize: 100,
      pageNumber: null,
      currentPageNumber: 1,
      startDate: yesterday.toISOString().substring(0, 16),
      endDate: today.toISOString().substring(0, 16),
      //alarmEventTypes: null,
      states: [],
      userAcknowledge: [],
    });

    const validationSchema = Yup.object().shape({
      //unit: Yup.string().required().label("Unit"),
      //unitDateRange: Yup.string().label("unitDateRange"),
      startDate: Yup.date().label("Period start"),
      endDate: Yup.date().label("Period end"),
    });

    const alarmEventStates = ref<Mast.GenericOption[]>([
      {} as Mast.GenericOption,
    ]);
    const alarmAcknowledgeStates = ref<Mast.GenericOption[]>([
      {} as Mast.GenericOption,
    ]);

    const getAlarmHistory = () => {
      const postData = {
        searchString: null,
        parentIds: null,
        pageSize: 30, //todo geert is dit genoeg
        pageNumber: null,
      } as any;
      return (
        //ApiService.post("/device/alarmevents", postData)
        ApiService.post("/device/alarmevents", searchFilter.value as any)
          .then(({ data }) => {
            console.log("alarmHistory response data:");

            console.log(data.results);
            alarmHistory.value = data.results;
          })
          .catch(({ response }) => {
            console.log("Fout alarmHistory");
          })
      );
    };

    const filterChanged = () => {
      console.log("refresh filter");
      getAlarmHistory();
    };
    var gridApi = null;
    const onGridReady = (params) => {
      gridApi = params.api;
      //this.gridColumnApi = params.columnApi;
    };
    const csvExport = () => {
      if (gridApi) (gridApi as any).exportDataAsCsv(); //getParams()
    };

    onMounted(() => {
      //setCurrentPageBreadcrumbs("Charts", ["Widgets"]);
      setCurrentPageTitle("Alarms");

      BnPHelpers.getOptions("/device/alarmeventstates", alarmEventStates);
      BnPHelpers.getOptions(
        "/device/alarmacknowledgestates",
        alarmAcknowledgeStates
      );
      getAlarmHistory();
      //MenuComponent.reinitialization();
    });

    const columnDefs = [
      {
        headerName: t("Alarm"),
        field: "alarm.displayName",
        sortable: true,
        filter: "agTextColumnFilter",
        filterParams: { buttons: ["apply", "cancel", "reset"] },
      },
      //{ headerName: t("Compare"), field: "alarm.compareValue" },
      {
        headerName: t("State"),
        field: "state.string",
        sortable: true,
        filter: true,
      },
      { headerName: t("Start"), field: "start", sortable: true, filter: true },
      {
        headerName: t("Value"),
        field: "startValue",
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: { buttons: ["apply", "cancel", "reset"] },
      },
      { headerName: t("End"), field: "end", sortable: true, filter: true },
      {
        headerName: t("Value"),
        field: "endValue",
        sortable: true,
        filter: true,
      },
      {
        headerName: t("Count"),
        field: "alarmCount",
        sortable: true,
        filter: "agNumberColumnFilter",
        filterParams: { buttons: ["apply", "cancel", "reset"] },
      },
      {
        headerName: t("Acknowledged"),
        field: "userAcknowledge.string",
        sortable: true,
        filter: true,
      },
    ];

    return {
      columnDefs,
      alarmHistory,
      filterChanged,
      validationSchema,
      searchFilter,
      alarmEventStates,
      alarmAcknowledgeStates,
      t,
      onGridReady,
      csvExport,
    };
  },
});
